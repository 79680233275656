import React from 'react';
import LayoutES from '../components/layout.es';
import Seo from '../components/seo.es';
import SolutionsBlockES from '../components/solutionsblock.component.es';
import MicroBenefit from '../components/microbenefit.component';
import BottomCTA from '../components/bottomCTA.component.es';

import Zoom from 'react-medium-image-zoom';
import '../styles/imagezoom.css';
import '../styles/product.scss';
import introProductImage from '../static/images/kuflow-design-science.gif';

export default function Product() {
	return (
		<LayoutES>
			<Seo title='En detalle' />
			<section id='introFAQ'>
				<div className='introFAQ-columns'>
					<div id='introFAQ-text' class='introProduct-column'>
						<img
							style={{ maxWidth: '240px' }}
							src={introProductImage}
							alt='Flujos profesionales'
						/>
						<h1>KuFlow en detalle</h1>
						<h2>KuFlow explicado como si NO fueras un niño de 5 años</h2>
					</div>
				</div>
			</section>
			<section id='productFAQ' style={{ marginBottom: '1rem' }}>
				<div className='benefits'>
					<MicroBenefit number='1'>
						<h3>Qué es KuFlow</h3>
						<p>
							KuFlow es una{' '}
							<b>
								plataforma orientada a desarrolladores sobre la que
								dise&ntilde;ar y ejectuar procesos de negocio
							</b>
							. Nos gusta llamarlo un{' '}
							<b>
								<i>orquestador de tareas</i>
							</b>
							, tanto humanas como automatizadas.
						</p>
						<ul>
							<li>
								En KuFlow defines un proceso de negocio como un conjunto de
								tareas relacionadas entre s&iacute;.
							</li>
							<li>
								KuFlow dispone de una interfaz web para poder definir los
								procesos y para que los usuarios puedan interactuar con cada
								tarea humana (rellenar un formulario, aprobar solicitudes,
								validar los resultados de una tarea autom&aacute;tica).
							</li>
							<li>
								El motor de KuFlow se encarga de ejecutar el flujo de las
								tareas, de forma robusta y persistente. Gestiona
								autom&aacute;ticamente los reintentos y los timeouts por ti.
							</li>
							<li>
								El contenido de cada tarea puede ser muy diverso, y est&aacute;
								siempre definido en c&oacute;digo.
							</li>
						</ul>
					</MicroBenefit>
					<MicroBenefit number='2'>
						<h3>Qué NO es KuFlow</h3>
						<ul>
							<li>
								<b>No es una herramienta BPM</b>. No utilizamos cajas y flechas
								para definir los flujos de los procesos de negocio. En su lugar,
								KuFlow se basa en c&oacute;digo.
							</li>
							<li>
								<b>
									No es una herramienta para reemplazar soluciones software como
									RRHH o Contabilidad
								</b>
								. En su lugar, KuFlow es una herramienta transversal para
								construir procesos de negocio de cualquier departamento o
								interdepartamentales.
							</li>
						</ul>
					</MicroBenefit>
				</div>
				<Zoom>
					<img
						src='/images/kuflow_screenshot_processlist_01_ES.png'
						width='600'
						style={{ margin: '0 auto' }}
						alt='Listado de procesos'
					/>
				</Zoom>
				<div className='benefits'>
					<MicroBenefit number='3'>
						<h3>Por qué KuFlow no es una herramienta LowCode</h3>
						<p>
							Despu&eacute;s de m&aacute;s de 15 a&ntilde;os desarrollando
							software a medida para empresas, el equipo de KuFlow ha
							comprendido que los procesos de negocio en ocasiones son
							simplemente demasiado complejos como para plantearlos con una
							herramienta Low-Code.{' '}
							<b>A veces la realidad no es tan sencilla</b>. Hacer un flujo de
							trabajo a trav&eacute;s de c&oacute;digo requiere una
							elaboraci&oacute;n m&aacute;s profunda y por personal cualificado,
							pero te permite toda la versatilidad posible y puede cubrir
							cualquier nivel de complejidad.
						</p>
						<p>
							<b>
								KuFlow est&aacute; construido por desarrolladores para
								desarrolladores.
							</b>
						</p>
					</MicroBenefit>
					<MicroBenefit number='4'>
						<h3>Para quién está pensado KuFlow</h3>
						<p>
							KuFlow tiene sentido para{' '}
							<b>organizaciones de cierto tama&ntilde;o</b>, con dos aspectos
							fundamentales:
						</p>
						<ul>
							<li>
								Disponen de <b>cierta complejidad en sus procesos de negocio</b>
								, que deben intentar resolver y automatizar en la medida de lo
								posible.
							</li>
							<li>
								<b>Disponen de un departamento IT</b> que debe dar respuesta y
								prestar soporte a las necesidades de transformaci&oacute;n
								digital de la organizaci&oacute;n.
							</li>
						</ul>
					</MicroBenefit>
				</div>
				<div className='use-cases'>
					<h2>Qué se puede hacer con KuFlow</h2>
					<div className='use-cases-columns'>
						<div className='use-case-column'>
							<h3>Implementar flujos de trabajo en las empresas</h3>
							<ul>
								<li>
									Pr&aacute;cticamente <b>cualquier flujo de trabajo</b>:
								</li>
								<ul>
									<li>
										Aprobaci&oacute;n de compras, desde la petici&oacute;n
										inicial hasta el contacto con el proveedor y almacenar la
										factura.
									</li>
									<li>Solicitar vacaciones en la empresa.</li>
									<li>
										Recibir feedback del cliente y reutilizar esa
										informaci&oacute;n.
									</li>
									<li>
										Gestionar la relaci&oacute;n con el cliente desde la
										propuesta inicial hasta el producto entregado.
									</li>
									<li>
										Recordar autom&aacute;ticamente a clientes documentos o
										autorizaciones pendientes.
									</li>
									<li>
										Revisar cada X tiempo alguna web y prepararte un informe.
									</li>
								</ul>
								<li>
									Si se puede pensar como un diagrama de flujos, se puede hacer
									en KuFlow.
								</li>
								<li>
									Para m&aacute;s informaci&oacute;n sobre este caso, visita{' '}
									<a href='https://kuflow.com/es/solution-workflow'>
										este enlace
									</a>
									.
								</li>
							</ul>
						</div>
						<div className='use-case-column'>
							<h3>Orquestación y la coordinación de soluciones RPA</h3>
							<ul>
								<li>
									Puedes{' '}
									<b>
										implementar RPA a trav&eacute;s de herramientas como
										RobotFramework.
									</b>
									.
								</li>
								<li>
									KuFlow se encargar&aacute; de hacer el proceso resiliente,
									gestionando los reintentos, gestionando los timeouts,
									gestionando los procesos as&iacute;ncronos y almacenando los
									estados de cada etapa.
								</li>
								<li>
									Para m&aacute;s informaci&oacute;n sobre este caso, visita{' '}
									<a href='https://kuflow.com/en/solution-rpa'>este enlace</a>.
								</li>
							</ul>
						</div>
						<div className='use-case-column'>
							<h3>Orquestación de servicios</h3>
							<ul>
								<li>
									<b>Cualquier tipo de orquestaci&oacute;n de servicios</b> o
									cualquier data pipeline puede verse beneficiado por la
									robustez que aporta el motor de KuFlow: retries, timeouts,
									etc.
								</li>
								<li>
									Para m&aacute;s informaci&oacute;n sobre este caso, visita{' '}
									<a href='https://kuflow.com/es/solution-orchestration/'>
										este enlace
									</a>
									.
								</li>
							</ul>
						</div>
					</div>
					<Zoom>
						<img
							src='/images/kuflow_screenshot_RPAdetails_01_ES.png'
							width='600'
							style={{ margin: '0 auto' }}
							alt='RPA reintentando'
						/>
					</Zoom>
				</div>
				<div className='benefits'>
					<MicroBenefit number='5' color='orange'>
						<h3>Cómo funciona</h3>
						<p>
							A grandes rasgos, una vez creada una cuenta de usuario y una
							organizaci&oacute;n, los procesos de negocio se implementan como
							sigue:
						</p>
						<ul>
							<li>
								<b>Se define un proceso y sus tareas</b>, registrando toda la
								informaci&oacute;n en KuFlow a trav&eacute;s de la web.
							</li>
							<li>
								<b>Se definen permisos</b> para aquellos usuarios que pueden
								iniciar los procesos o hacer determinadas tareas.
							</li>
							<li>
								<b>Se implementa el Workflow Worker</b> a trav&eacute;s de
								c&oacute;digo.
							</li>
							<li>
								<b>Se despliega el Workflow Worker</b> en tu propia
								infraestructura y KuFlow actuar&aacute; como motor de
								orquestaci&oacute;n en cada paso del proceso.
							</li>
						</ul>
						<p>
							La arquitectura a nivel t&eacute;cnico se comprende viendo nuestra{' '}
							<a href='https://docs.kuflow.com/developers/architecture'>
								documentaci&oacute;n para desarrolladores
							</a>
							.
						</p>
					</MicroBenefit>
					<MicroBenefit number='6'>
						<h3>Cómo podría probar la herramienta</h3>
						<p>
							KuFlow dispone de una <b>capa de uso gratuita</b>, que contempla
							todas las funcionalidades y sus &uacute;nicas restricciones son el
							n&uacute;mero de agentes y el espacio empleado por cada
							organizaci&oacute;n. Puedes registrarte{' '}
							<a href='http://app.kuflow.com/'>aqu&iacute;</a>.
						</p>
						<p>
							Existen muchas formas de comenzar a utilizar KuFlow, pero hemos
							pensado que un <b>tutorial</b> puede resultar muy intuitivo. Hemos
							implementado el caso de uso de una solicitud de pr&eacute;stamos
							para contemplar tareas humanas (la solicitud y la
							aprobaci&oacute;n), flujos de decisi&oacute;n (a partir del
							importe del pr&eacute;stamo, este se concede sin necesidad de
							aprobaci&oacute;n) y ciertas tareas autom&aacute;ticas (como la
							conversi&oacute;n de moneda). El tutorial est&aacute; disponible
							en{' '}
							<a href='https://www.youtube.com/watch?v=GUPRV7UOpr0'>
								este vídeo
							</a>{' '}
							y toda la documentaci&oacute;n, incluyendo el c&oacute;digo
							est&aacute;{' '}
							<a href='https://docs.kuflow.com/developers/examples/curated/java/java-loantutorial'>
								en este enlace
							</a>
							.
						</p>
					</MicroBenefit>
					<MicroBenefit number='7'>
						<h3>¿Y a partir de aquí?</h3>
						<p>
							Continuamos trabajando en KuFlow con diversas funcionalidades,
							como una herramienta de diagrama para serverless workflows, las
							funcionalidades de anal&iacute;tica y la construcci&oacute;n de
							infraestructura para que los workers puedan ejecutarse en el Cloud
							de KuFlow.
						</p>
						<p>
							Estamos en una fase temprana de desarrollo, y estamos{' '}
							<b>
								totalmente abiertos a sugerencias y a las opiniones de la
								comunidad
							</b>
							.
						</p>
					</MicroBenefit>
				</div>
			</section>
			<SolutionsBlockES />
			<BottomCTA />
		</LayoutES>
	);
}
